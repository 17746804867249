<!--
 * @Descripttion : 订单轨迹
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-11 14:55:52
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-11 16:16:45
-->
<template>
    <div>
        <template v-if="orderObj.businessLocus&&orderObj.businessLocus.length>0">
            <a-timeline mode="alternate">
                <template v-for="(item,index) in orderObj.businessLocus">
                    <a-timeline-item :key="index" color="green">
                        <div>{{item.operateTime}} </div>
                        <div>
                            <b>{{item.title}}</b>
                        </div>
                        <div>
                            备注：{{item.content}}
                        </div>
                        <div v-if="item.operateRealName">操作人：{{item.operateRealName}}</div>
                        <div v-if="item.imgUrls" class="imgUrls">
                            <viewer ref="viewer" :images="item.imgUrls" rebuild @inited="inited" class="viewer" style="padding:15px 0;">
                                <a-card hoverable size="small" class="ml_order_card" v-for="(item,index) in item.imgUrls" :key="index">
                                    <img slot="cover" :src="item" :data-src="item" :key="item" class="ml_order_img" :alt="'图片备注'+(index+1)">
                                </a-card>
                            </viewer>
                        </div>
                    </a-timeline-item>
                </template>
            </a-timeline>
        </template>
        <template v-else>
            <a-empty />
        </template>
    </div>
</template>
<script>
export default {
    props: {
        orderObj: {
            type: Object,
            default: null
        }
    }
};
</script>

<style lang="scss">
.imgUrls .ml_order_card {
    width: 120px !important;
    .ml_order_img {
        height: 90px !important;
        line-height: 90px !important;
    }
}
</style>