/*
 * @Descripttion : 阿里云对接
 * @version      :
 * @Author       : ml
 * @Date         : 2020-09-25 12:56:09
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-25 13:49:56
 */
const OSS = require('ali-oss')

export default new OSS({
	region: 'oss-cn-hangzhou',
	accessKeyId: 'LTAItM1cRO8xWdMJ',
	accessKeySecret: 'bUfxQX6blYuhoTP4JLLgo2Ln1domlN',
	bucket: 'carwins-images',
})
