<!--
 * @Descripttion : 上传资料显示
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-08 16:35:28
 * @LastEditors  : min
 * @LastEditTime : 2020-12-22 20:48:18
-->
<template>
  <div class="ml_file_list">
    <div class="ml_order_title">
      办证资料
    </div>
    <a-tabs v-model="tabIndexKey" tab-position="left">
      <a-tab-pane key="2" tab="新车主">
        <template v-if="newCarData.length>0&&!isPhone">
          <a-button type="primary" icon="download" size="small" @click="downFileZip">
            新车主办证资料
          </a-button>
        </template>
        <template v-if="newCarData.length>0">
          <viewer ref="viewer" :images="newCarData" rebuild @inited="inited" class="viewer">
            <template v-for="(item,index) in newCarData">
              <div :key="index">
                <a-divider>{{item.name}}</a-divider>
                <template v-for="itemlist in item.childFileUploadSet">
                  <template v-if="itemlist.fileInfo.length>0">
                    <template v-for="(items,indexs) in itemlist.fileInfo">
                      <a-card hoverable size="small" class="ml_order_card" :title="itemlist.name" :key="items.id">
                        <!-- jpg,png,docx,xml,pdf  +'?x-oss-process=image/resize,w_1280,h_960/quality,q_90' -->
                        <template v-if="items.contentType=='image/jpg'||items.contentType=='image/png'||items.contentType=='image/jpeg'">
                          <img slot="cover" :src="items.url" :data-src="items.url" :key="items.url" class="ml_order_img" :alt="'新车主'+item.name+(indexs+1)">
                        </template>
                        <template v-if="items.contentType=='application/msword'||items.contentType=='docx'">
                          <div slot="cover" class="ml_order_img">
                            <img src="../../assets/word.png" class="ml_order_icon" />
                          </div>
                        </template>
                        <template v-if="items.contentType=='application/vnd.ms-excel'||items.contentType=='xls'">
                          <div slot="cover" class="ml_order_img">
                            <img src="../../assets/excel.png" class="ml_order_icon" />
                          </div>
                        </template>
                        <template v-if="items.contentType=='application/pdf'">
                          <div slot="cover" class="ml_order_img">
                            <img src="../../assets/pdf.png" class="ml_order_icon" />
                          </div>
                        </template>

                        <template v-if="items.contentType=='audio/mp4'||items.contentType=='video/mp4'">
                          <div slot="cover" class="ml_order_img">
                            <video :src="items.url" class="ml_order_img" controls></video>
                          </div>
                        </template>
                      </a-card>
                    </template>
                  </template>
                  <template v-else>
                    <a-card hoverable size="small" class="ml_order_card" :title="itemlist.name">
                      <a-empty />
                    </a-card>
                  </template>
                </template>
              </div>
            </template>
          </viewer>
        </template>
        <template v-else>
          <a-empty />
        </template>
      </a-tab-pane>
      <a-tab-pane key="1" tab="原车主">
        <template v-if="oldCarData.length>0&&!isPhone">
          <a-button type="primary" icon="download" size="small" @click="downFileZip">
            原车主办证资料
          </a-button>
        </template>
        <template v-if="oldCarData.length>0">
          <viewer ref="viewer" :images="oldCarData" rebuild @inited="inited" class="viewer">
            <template v-for="(item,index) in oldCarData">
              <div :key="index">
                <a-divider>{{item.name}}</a-divider>
                <template v-for="itemlist in item.childFileUploadSet">
                  <template v-if="itemlist.fileInfo.length>0">
                    <template v-for="(items,indexs) in itemlist.fileInfo">
                      <a-card hoverable size="small" class="ml_order_card" :title="itemlist.name" :key="items.id">
                        <!-- jpg,png,doc,docx,xls,xlsx,pdf -->
                        <template v-if="items.contentType=='image/jpg'||items.contentType=='image/png'||items.contentType=='image/jpeg'">
                          <img slot="cover" :src="items.url" :data-src="items.url" :key="items.url" class="ml_order_img" :alt="'原车主'+item.name+(indexs+1)">
                        </template>
                        <template v-if="items.contentType=='application/msword'||items.contentType=='docx'">
                          <div slot="cover" class="ml_order_img">
                            <img src="../../assets/word.png" class="ml_order_icon" />
                          </div>
                        </template>
                        <template v-if="items.contentType=='application/vnd.ms-excel'||items.contentType=='xls'">
                          <div slot="cover" class="ml_order_img">
                            <img src="../../assets/excel.png" class="ml_order_icon" />
                          </div>
                        </template>
                        <template v-if="items.contentType=='application/pdf'">
                          <div slot="cover" class="ml_order_img">
                            <img src="../../assets/pdf.png" class="ml_order_icon" />
                          </div>
                        </template>

                        <template v-if="items.contentType=='audio/mp4'||items.contentType=='video/mp4'">
                          <div slot="cover" class="ml_order_img">
                            <video :src="items.url" class="ml_order_img" controls></video>
                          </div>
                        </template>
                      </a-card>
                    </template>
                  </template>
                  <template v-else>
                    <a-card hoverable size="small" class="ml_order_card" :title="itemlist.name">
                      <a-empty />
                    </a-card>
                  </template>
                </template>
              </div>
            </template>
          </viewer>
        </template>
        <template v-else>
          <a-empty />
        </template>

      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
var JSZip = require("jszip");
import { saveAs } from "file-saver";

import client from "@/utils/ali-oss";
import { getOrderFile, getExternalOrderFile } from "@/api/fileUpload";

export default {
  props: {
    orderObj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      oldCarData: [], //  原车主数据
      newCarData: [], // 新车主数据
      isPhone: false, //  是否手机端
      isErp: false,
      isLMP: false,
      tabIndexKey: "2"
    };
  },
  mounted() {
    // 是否来源于erp
    if (
      sessionStorage.getItem("cbz_appid") &&
      sessionStorage.getItem("cbz_timestamp")
    ) {
      this.isErp = true;
    } else {
      this.isErp = false;
    }

    // 是否来自原联盟拍
    if (this.$route.query.source == "lmp") {
      this.isLMP = true;
    } else {
      this.isLMP = false;
    }

    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
      //移动端
      this.isPhone = true;
    }

    // this.oldCarData = this.orderObj.oldCertificationFile;
    // this.newCarData = this.orderObj.newCertificationFile;
    if (this.isErp || this.isLMP) {
      this.getExternalOrderFile();
    } else {
      this.getOrderFile();
    }
  },
  methods: {
    getOrderFile() {
      const that = this;
      getOrderFile(that.orderObj.orderNumber).then(res => {
        res.data.forEach(element => {
          element.childFileUploadSet.forEach(item => {
            item["fileList"] = [];
            item["uploadList"] = [];
            item.fileInfo.forEach(items => {
              item.fileList.push({
                uid: items.id,
                name: items.fileName,
                status: "done",
                response: "",
                url: items.url
              });
              item.uploadList.push(items.url);
            });
          });

          if (element.type == "ceroldown") {
            that.oldCarData.push(element);
          } else {
            that.newCarData.push(element);
          }
        });
      });
    },
    getExternalOrderFile() {
      // erp获取资料详情
      const that = this;
      getExternalOrderFile(that.orderObj.orderNumber).then(res => {
        res.data.forEach(element => {
          element.childFileUploadSet.forEach(item => {
            item["fileList"] = [];
            item["uploadList"] = [];
            item.fileInfo.forEach(items => {
              item.fileList.push({
                uid: items.id,
                name: items.fileName,
                status: "done",
                response: "",
                url: items.url
              });
              item.uploadList.push(items.url);
            });
          });

          if (element.type == "ceroldown") {
            that.oldCarData.push(element);
          } else {
            that.newCarData.push(element);
          }
        });
      });
    },
    inited(viewer) {
      //   console.log(viewer);
      const that = this;
      that.$viewer = viewer;

      if (that.isPhone) {
        that.$viewer.show = () => {
          console.log(viewer.index);

          console.log(viewer.images);
          let _images = "";
          viewer.images.forEach(element => {
            if (_images.length > 0) {
              _images += "|";
            }
            _images += element.currentSrc;
          });

          location.href =
            "carwins://go?page=photobrowser#images=" +
            _images +
            "&selectedIndex=" +
            viewer.index;
        };
      }

      //   that.$viewer.reset = () => {
      //     let _downUrl = that.$viewer.image.currentSrc.replace(
      //       "?x-oss-process=image/resize,w_1280,h_960/quality,q_90",
      //       ""
      //     );
      //     const filename = that.$viewer.image.alt + ".jpg"; // filename为自定义下载后的文件名。
      //     const response = {
      //       "content-disposition": `attachment; filename=${encodeURIComponent(
      //         filename
      //       )}`
      //     };
      //     let _arr = _downUrl.split("/");
      //     let _obgkey = _arr[_arr.length - 2] + "/" + _arr[_arr.length - 1];
      //     // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
      //     const url = client.signatureUrl(_obgkey, { response });
      //     saveAs(url, that.$viewer.image.alt + ".jpg");
      //   };
    },
    downFileZip() {
      // 打包下载文件
      const that = this;
      var zip = new JSZip();

      let arrImages = [];
      if (that.tabIndexKey == 1) {
        // 原车主
        that.oldCarData.forEach(itemElement => {
          itemElement.childFileUploadSet.forEach(element => {
            element.fileInfo.forEach(item => {
              if (item.url) {
                arrImages.push({
                  fileUrl: item.url,
                  renameFileName:
                    element.name + item.id + "." + item.contentType.split("/")[1]
                });
              }
            });
          });
        });
      } else {
        // 新车主
        that.newCarData.forEach(itemElement => {
          itemElement.childFileUploadSet.forEach(element => {
            element.fileInfo.forEach(item => {
              if (item.url) {
                arrImages.push({
                  fileUrl: item.url,
                  renameFileName:
                    element.name + item.id + "." + item.contentType.split("/")[1]
                });
              }
            });
          });
        });
      }

      if (arrImages.length > 0) {
        let cache = {};
        let promises = [];

        for (let item of arrImages) {
          const filename = item.renameFileName; // filename为自定义下载后的文件名。
          const response = {
            "content-disposition": `attachment; filename=${encodeURIComponent(
              filename
            )}`
          };
          let _arr = item.fileUrl.split("/");
          let _obgkey = _arr[_arr.length - 2] + "/" + _arr[_arr.length - 1];
          // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
          const url = client.signatureUrl(_obgkey, { response });

          const promise = that.getImgArrayBuffer(url).then(data => {
            // 下载文件, 并存成ArrayBuffer对象(blob)
            zip.file(item.renameFileName, data, { binary: true }); // 逐个添加文件
            cache[item.renameFileName] = data;
          });
          promises.push(promise);
        }

        let downFileName = "";

        if (that.tabIndexKey == 1) {
          downFileName =
            that.orderObj.carVin +
            "-" +
            that.orderObj.merchantName +
            "-原车主办证资料.zip";
        } else {
          downFileName =
            that.orderObj.carVin +
            "-" +
            that.orderObj.merchantName +
            "-新车主办证资料.zip";
        }
        Promise.all(promises)
          .then(() => {
            zip.generateAsync({ type: "blob" }).then(content => {
              that.title = "正在压缩";
              // 生成二进制流
              saveAs.saveAs(content, downFileName); // 利用file-saver保存文件  自定义文件名
              that.title = "压缩完成";
            });
          })
          .catch(res => {
            that.$message.error("文件压缩失败" + res);
          });
      } else {
        that.$message.error("暂无可下载办证资料");
      }
    },
    getImgArrayBuffer(url) {
      //   let _this = this;
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", url, true);
        xmlhttp.responseType = "blob";
        xmlhttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response);
          } else {
            reject(this.status);
          }
        };
        xmlhttp.send();
      });
    }
  }
};
</script>