<!--
 * @Descripttion : 办证提车详情PC
 * @version      : 
 * @Author       : karl
 * @Date         : 2022-01-06 10:20:38
 * @LastEditors  : karl
 * @LastEditTime : 
-->
<template>
    <div>
        <!-- str -->
        <div class="stepsBox">
            <a-steps :current="stepsCur">
                <a-step v-for="(item,index) in detailData.locus" :key="index" :title="item.statusName" :description="item.selected==1?item.time:''"></a-step>
            </a-steps>
        </div>

        <a-descriptions title="办证接车人信息" bordered size="small" :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
            <a-descriptions-item label="姓名">
                {{detailData.pickUpCarRealName||'-'}}
            </a-descriptions-item>
            <a-descriptions-item label="手机号">
                {{detailData.pickUpCarUserPhone||'-'}}
            </a-descriptions-item>
            <a-descriptions-item label="业务编号">
                {{detailData.businessCode||'-'}}
            </a-descriptions-item>
            <a-descriptions-item label="指派备注">
                {{detailData.assignRemark||'-'}}
            </a-descriptions-item>
        </a-descriptions>

        <viewer ref="viewer" :images="detailData.assignImages" rebuild @inited="inited" class="viewer" v-if="assignImagesLength>0" style="padding:15px 0;">
            <a-card hoverable size="small" class="ml_order_card" v-for="(item,index) in detailData.assignImages" :key="index">
                <img slot="cover" :src="item" :data-src="item" :key="item" class="ml_order_img" :alt="'指派图片'+(index+1)">
            </a-card>
        </viewer>

        <a-descriptions title="证件交接信息" bordered size="small" :column="{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }">
            <a-descriptions-item label="交接证件">
                {{detailData.documentsStr||'-'}}
            </a-descriptions-item>
            <a-descriptions-item label="门店备注">
                {{detailData.deliverRemark||'-'}}
            </a-descriptions-item>
            <a-descriptions-item label="开票金额">
                {{detailData.usedCarInvoicedAmount?detailData.usedCarInvoicedAmount+'元':'-'}}
            </a-descriptions-item>
            <a-descriptions-item label="安全行驶">
                {{detailData.safeDrivingStateDesc||'-'}}
            </a-descriptions-item>
        </a-descriptions>

        <viewer ref="viewer" :images="imagesList" rebuild @inited="inited" class="viewer" v-if="imagesList.length>0" style="padding:15px 0;">
            <a-card hoverable size="small" class="ml_order_card" v-for="(item,index) in imagesList" :title="item.name" :key="index">
                <img slot="cover" :src="item.url" :data-src="item.url" :key="item.url" class="ml_order_img" :alt="item.name">
            </a-card>
        </viewer>

        <div style="padding-top:50px;text-align:center;">
            <a-button type="primary" @click="onSubmitPickUpCar" :disabled="isSubmitDisable" v-if="detailData.isShowConfirmPickUpCar==1&&isViewPickUp!=1"> 确认收车 </a-button>
        </div>
        <!-- end -->
    </div>
</template>
        
<script>
import {
    getPCDetails,
    confirmPickUpCar
} from "@/api/pickUpCar";

export default {
    name: "pickUpCarInfoDetail",
    components: {

    },
    props: {
        orderObj: {
            type: Object,
            default: null
        },
        isViewPickUp: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            isErp: false,
            isPhone: false, //  是否手机端
            detailData: {},
            assignImagesLength: 0,
            imagesList: [],
            stepsCur: 1,
            isSubmitDisable: false,
            orderNumber: '',
            viewNoConfirmPickUpCar: 0
        }
    },
    mounted() {
        // 是否来源于erp
        if (sessionStorage.getItem("cbz_appid") && sessionStorage.getItem("cbz_timestamp")) {
            this.isErp = true;
        } else {
            this.isErp = false;
        }

        if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
            //移动端
            this.isPhone = true;
        } else {
            this.isPhone = false;
        }
        if (this.orderObj.pickUpCarStatus > 0) {
            this.orderNumber = this.orderObj.orderNumber
            this.getDetail()
        }
    },
    methods: {
        getDetail() {
            const that = this;
            that.loading = true;
            getPCDetails(that.orderNumber + '/' + (that.isErp ? 'erp' : 'bz'), {
                _: new Date().getTime()
            }).then(res => {
                that.loading = false;
                that.detailData = res.data
                if (res.data.assignImages && res.data.assignImages.length > 0) {
                    that.assignImagesLength = res.data.assignImages.length
                }
                that.imagesList = [];
                if (res.data.pickUpManAndCarImage) {
                    that.imagesList.push({
                        name: '提车人与车辆',
                        url: res.data.pickUpManAndCarImage
                    })
                }
                if (res.data.documentsImage) {
                    that.imagesList.push({
                        name: '证件合照',
                        url: res.data.documentsImage
                    })
                }
                if (res.data.otherImages && res.data.otherImages.length > 0) {
                    res.data.otherImages.forEach(element => {
                        that.imagesList.push({
                            name: '其他照片',
                            url: element

                        })
                    });
                }

                res.data.locus.forEach((element, index) => {
                    if (element.selected == 1) {
                        that.stepsCur = (index + 1);
                    }
                });
            }).catch(err => {
                console.log('失败:' + err);
                that.loading = false;
            });
        },
        inited(viewer) {
            const that = this;
            that.$viewer = viewer;
            if (that.isPhone) {
                that.$viewer.show = () => {
                    let _images = "";
                    viewer.images.forEach(element => {
                        if (_images.length > 0) {
                            _images += "|";
                        }
                        _images += element.currentSrc;
                    });
                    location.href = "carwins://go?page=photobrowser#images=" + _images + "&selectedIndex=" + viewer.index;
                };
            }
        },
        onSubmitPickUpCar() {
            const that = this;
            that.$confirm({
                title: "温馨提示",
                content: "您确认收车吗？",
                centered: true,
                onOk() {
                    that.loading = true;
                    that.isSubmitDisable = true;
                    confirmPickUpCar({
                        id: that.detailData.id,
                        status: 3 //状态(1:接车指派,2:门店交车确认,3:办证收车确认)
                    }).then(() => {
                        that.loading = false;
                        setTimeout(() => {
                            that.isSubmitDisable = false; //阻止重复提交
                        }, 2000)
                        that.$message.success({
                            content: "操作成功！",
                            duration: 1,
                            onClose: () => {
                                that.getDetail(that.orderObj.orderNumber)
                            },
                        });
                    }).catch((err) => {
                        that.isSubmitDisable = false; //阻止重复提交
                        console.log('失败：' + err);
                    });
                },
                onCancel() { }
            });
        }
    },
}
</script>

<style lang="scss">
.stepsBox {
    width: 80%;
    margin: 0 auto;
    padding: 50px 0 30px 0;
    .ant-steps-item-title {
        height: 50px;
    }
    .ant-steps-item-description {
        color: #333;
    }
}
</style>
<style lang="scss" scoped>
</style>