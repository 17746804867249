/*
 * @Descripttion: 发票识别
 * @version: 
 * @Author: min
 * @Date: 2020-10-30 20:23:24
 * @LastEditors  : min
 * @LastEditTime : 2020-12-22 15:44:16
 */
import request from './request'

// 发票人工核验
export function invoiceArtificialCheck(data) {
	return request.post('InvoiceRecognition/invoiceArtificialCheck', data)
}

// 修改证件核验数据
export function updateInvoiceCheckData(data) {
	return request.post("InvoiceRecognition/updateInvoiceCheckData", data)
}

// 证件单项核验
export function invoiceArtificialSingleCheck(data) {
	return request.post("InvoiceRecognition/invoiceArtificialSingleCheck", data)
}

// 对外根据订单编号获取 办证订单信息
export function externalDetails(data) {
	return request.fetch("InvoiceRecognition/externalDetails/" + data)
}

// 根据订单编号获取 办证订单信息
export function getDetails(data) {
	return request.fetch("InvoiceRecognition/getDetails/" + data)
}
