<!--
 * @Descripttion : 订单基础信息
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-28 10:36:04
 * @LastEditors  : min
 * @LastEditTime : 2020-12-22 16:50:32
-->
<template>
    <div>
        <a-descriptions title="" size="small" class="ml_order_table" :column="{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }">
            <a-descriptions-item label="订单编号">
                {{ orderObj.orderNumber }}
            </a-descriptions-item>
            <a-descriptions-item label="订单状态">
                <!-- 1:办证中，2:平台待审核，3：机构待审核，4，已完结，5：平台驳回，6：机构驳回 -->
                <span class="ml_state_color">
                    <template v-if="orderObj.state == 1">
                        办证中
                    </template>
                    <template v-if="orderObj.state == 2">
                        平台待审核
                    </template>
                    <template v-if="orderObj.state == 3">
                        机构待审核
                    </template>
                    <template v-if="orderObj.state == 4">
                        已完结
                    </template>
                    <template v-if="orderObj.state == 5">
                        平台驳回
                    </template>
                    <template v-if="orderObj.state == 6">
                        机构驳回
                    </template>
                </span>
            </a-descriptions-item>
            <a-descriptions-item label="办证类型">
                <!-- 1:平台办理，2：商户自办 -->
                <template v-if="orderObj.type == 1">平台办理</template>
                <template v-if="orderObj.type == 2">商户自办</template>
            </a-descriptions-item>
            <a-descriptions-item label="过户类型">
                <!-- 1:本地过户，2：转籍 -->
                <template v-if="orderObj.transferType == 1">本地过户</template>
                <template v-else-if="orderObj.transferType == 2">转籍</template>
                <template v-else>-</template>
            </a-descriptions-item>
            <a-descriptions-item label=" 创建人">
                {{ orderObj.createUserName || "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="创建日期">
                {{ orderObj.createTime || "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="证件核对">
                {{ orderObj.invoiceCheckStatusName || "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="完结时间">
                {{ orderObj.overTime || "-" }}
            </a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="车辆信息" bordered size="small" :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
            <a-descriptions-item label="VIN码">
                {{ orderObj.carVin }}
            </a-descriptions-item>
            <a-descriptions-item label="车辆名称">
                {{ orderObj.carName }}
            </a-descriptions-item>
            <a-descriptions-item label="车牌">
                {{ orderObj.carPlate }}
            </a-descriptions-item>
        </a-descriptions>


        <div style="position: relative;">
            <div class="tit_btn_box" v-if="btnVisible">
                <a-button type="primary" size="small" @click="modalVisible = true">过户延期</a-button>
            </div>

            <a-descriptions title="机构信息" bordered size="small" :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
                <a-descriptions-item label="机构门店">
                    {{ orderObj.carInstitutionName || "-" }} {{ orderObj.carInstitutionStoreName ? ' - ' + orderObj.carInstitutionStoreName : '' }}
                </a-descriptions-item>
                <a-descriptions-item label="机构联系人">
                    {{ orderObj.carInstitutionStoreContacts || "-" }}
                </a-descriptions-item>
                <a-descriptions-item label="店面地址">
                    {{ orderObj.carInstitutionStoreAddress || "-" }}
                </a-descriptions-item>
                <a-descriptions-item label="店面电话">
                    {{ orderObj.carInstitutionStorePhone || "-" }}
                </a-descriptions-item>
                <a-descriptions-item label="过户期限">
                    {{ orderObj.cutOffTime || "-" }}
                </a-descriptions-item>
            </a-descriptions>
        </div>

        <a-descriptions title="买家信息" bordered size="small" :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }">
            <a-descriptions-item label="买家姓名">
                {{ orderObj.merchantName || "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="买家电话">
                {{ orderObj.merchantPhone || "-" }}
            </a-descriptions-item>
            <a-descriptions-item label="落档城市">
                {{ orderObj.settleArchivesProvinceName || "-" }} {{ orderObj.settleArchivesCityName ? ' -' + orderObj.settleArchivesCityName : '' }}
            </a-descriptions-item>
        </a-descriptions>

        <div class="ml_order_title">
            办证说明
        </div>

        <div class="ml_order_des">
            {{ orderObj.certificationDeclare || "-" }}
        </div>

        <a-modal v-model="modalVisible" title="修改截止日期" centered @ok="onSubmitTimeForERP">
            <a-form-model layout="inline">
                <a-form-item label="截止日期">
                    <a-date-picker v-model="form.cutOffTime" format="YYYY-MM-DD HH:mm:ss" :show-time="{ format: 'HH:mm:ss' }" @change="onChangeTime" />
                </a-form-item>
            </a-form-model>
        </a-modal>

    </div>
</template>
<script>
// import moment from "moment";
// import "moment/locale/zh-cn";
// moment.locale("zh-cn");

import {
    updateCutOffTimeForERP, //修改过户截止时间（ERP的H5详情调用，同步给联盟拍）
} from "@/api/order";

export default {
    props: {
        orderObj: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            // source
            btnVisible: false,
            modalVisible: false,
            form: {
                appId: "",
                businessId: "",
                cutOffTime: "",
                operatorName: ""
            },
        };
    },
    mounted() {
        const that = this;
        that.form.appId = sessionStorage.getItem("cbz_appid");
        that.form.operatorName = sessionStorage.getItem("cbz_username");
        that.form.businessId = that.orderObj.orderNumber;

        // 来源于erp/saas，非联盟拍
        if ((sessionStorage.getItem("cbz_appid") && sessionStorage.getItem("cbz_timestamp")) && that.$route.query.source != 'lmp' && that.orderObj.state != 4) {
            that.btnVisible = true;
        } else {
            that.btnVisible = false;
        }

        console.log('get', that.btnVisible)

        // console.log(that.orderObj);


    },
    methods: {
        onChangeTime(date, dateString) {
            const that = this;
            // that.form.cutOffrime = moment(dateString, "YYYY-MM-DD HH:mm:ss");
            that.form.cutOffTime = dateString;
        },
        onSubmitTimeForERP() {
            const that = this;

            if (!that.form.cutOffTime) {
                that.$message.error({
                    content: "请选择截止日期",
                    duration: 1,
                });
                return false;
            }

            updateCutOffTimeForERP(that.form).then(() => {
                that.modalVisible = false;
                that.$message.success({
                    content: "修改成功！",
                    duration: 1,
                    onClose: () => {
                        location.reload();
                    },
                });
            }).catch((err) => {
                console.log('失败：' + err);
                that.modalVisible = false;
            });


        }
    },
};
</script>
<style lang="scss" scoped>
.ml_state_color {
    color: #2db7f5;
}

.tit_btn_box {
    position: absolute;
    top: 20px;
    left: 100px;
}
</style>