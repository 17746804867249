<!--
 * @Descripttion : 证件核对
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-21 14:35:30
 * @LastEditors  : min
 * @LastEditTime : 2020-12-23 19:45:27
-->
<template>
    <div class="ml_certificate">
        <a-divider orientation="left">
            <h3>
                信息核对项目 <span style="font-size:14px;font-weight:100;color:#ff7901;">系统仅对以下各项数据在多个证件中的信息进行核对，核对结果仅供参考，最终结果以机构方最终核验为准。</span>
            </h3>
        </a-divider>
        <a-row :gutter="15">
            <a-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-space direction="vertical" style="width: 100%;padding-bottom:15px" size="middle">
                    <a-card size="small">
                        <template slot="title">
                            <a-icon type="check-circle" v-if="certificateObj1.status==1" style="color:green;" />
                            <a-icon type="close-circle" style="color:red;" v-else />
                            {{ certificateObj1.title }}
                            <a-icon type="link" style="font-size:22px;color:#1890ff" />&nbsp;&nbsp;<a target="_blank" style="display: inline;" href="https://inv-veri.chinatax.gov.cn/">国税总局发票核验网址</a>
                        </template>
                        <p v-for="(item,index) in certificateObj1.array" :key="index">
                            <a-icon type="check-circle" v-if="certificateObj1.status==1" style="color:green;" />
                            <a-icon type="close-circle" style="color:red;" v-else />
                            {{ item.title }}
                        </p>
                        <p v-for="(item,index) in certificateObj1.list" :key="index">
                            <a-icon type="check-circle" v-if="certificateObj1.status==1" style="color:green;" />
                            <a-icon type="close-circle" style="color:red;" v-else />
                            {{ item.title }}:{{ item.value }}
                        </p>
                        <template v-if="certificateObj1.status!=1&&!isErp&&!isLMP">
                            <a-button type="primary" block @click="invoiceArtificialSingleCheck(8)">
                                人工核对确认数据一致
                            </a-button>
                        </template>
                    </a-card>

                    <template v-for="(item,index) in certificateList">
                        <a-card size="small" :key="index">
                            <template slot="title">
                                <a-icon type="check-circle" v-if="item.status==1" style="color:green;" />
                                <a-icon type="close-circle" style="color:red;" v-else />
                                {{ item.title }}
                            </template>
                            <p v-for="(items,indexs) in item.array" :key="indexs">
                                <template v-if="items.title==formInline.title&&item.status!=1&&!isErp&&!isLMP">
                                    <a-form-model layout="inline" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
                                        <a-form-model-item :label="formInline.title">
                                            <a-input v-model="formInline.value" :placeholder="formInline.title"></a-input>
                                        </a-form-model-item>
                                        <a-form-model-item>
                                            <a-button type="link" html-type="submit" :disabled="formInline.value === ''">
                                                确定
                                            </a-button>
                                        </a-form-model-item>
                                    </a-form-model>
                                </template>
                                <template v-else>
                                    <a-row>
                                        <a-col :span="12">
                                            {{ items.title }}: {{ items.value }}
                                        </a-col>
                                        <a-col :span="12" v-if="item.status!=1&&!isErp&&!isLMP">
                                            <a-button type="link" icon="edit" @click="editForm(item,items,index,indexs)">
                                                修正
                                            </a-button>
                                        </a-col>
                                    </a-row>
                                </template>
                            </p>
                            <p v-for="(items,indexs) in item.list" :key="indexs">
                                <a-icon type="check-circle" v-if="items.value==1" style="color:green;" />
                                <a-icon type="close-circle" style="color:red;" v-else />
                                {{ items.title }}: 核对一致
                            </p>
                            <template v-if="item.status!=1&&!isErp&&!isLMP">
                                <a-button type="primary" block @click="invoiceArtificialSingleCheck(index+1)">
                                    人工核对确认数据一致
                                </a-button>
                            </template>
                        </a-card>
                    </template>

                    <a-card size="small">
                        <template slot="title">
                            <a-icon type="check-circle" v-if="certificateObj2.status==1" style="color:green;" />
                            <a-icon type="close-circle" style="color:red;" v-else />
                            {{ certificateObj2.title }}
                        </template>
                        <p v-for="(item,index) in certificateObj2.list" :key="index">
                            <a-icon type="check-circle" v-if="item.value==1" style="color:green;" />
                            <a-icon type="close-circle" style="color:red;" v-else />
                            {{ item.title }}
                        </p>
                        <template v-if="certificateObj2.status!=1&&!isErp&&!isLMP">
                            <a-button type="primary" block @click="invoiceArtificialSingleCheck(7)">
                                人工核对确认数据一致
                            </a-button>
                        </template>
                    </a-card>
                </a-space>
            </a-col>
            <a-col :span="8" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <a-space direction="vertical" size="middle">
                    <template v-if="invoiceCheckDetails.invoiceImageUrl">
                        <a-card size="small" title="二手车销售统一发票">
                            <zoom-on-hover :imgNormal="invoiceCheckDetails.invoiceImageUrl" :scale="1.5"></zoom-on-hover>
                        </a-card>
                    </template>
                    <template v-if="invoiceCheckDetails.identityCardPortraitImageUrl">
                        <a-card size="small" title="身份证人像面">
                            <zoom-on-hover :imgNormal="invoiceCheckDetails.identityCardPortraitImageUrl" :scale="1.5"></zoom-on-hover>
                        </a-card>
                    </template>
                    <template v-if="invoiceCheckDetails.businessLicenseImageUrl">
                        <a-card size="small" title="营业执照">
                            <zoom-on-hover :imgNormal="invoiceCheckDetails.businessLicenseImageUrl" :scale="1.5"></zoom-on-hover>
                        </a-card>
                    </template>
                    <template v-if="invoiceCheckDetails.vehicleRegistrationCertificateImageUrl">
                        <a-card size="small" title="车辆登记证书第1-2页">
                            <zoom-on-hover :imgNormal="invoiceCheckDetails.vehicleRegistrationCertificateImageUrl" :scale="1.5"></zoom-on-hover>
                        </a-card>
                    </template>
                    <template v-if="invoiceCheckDetails.drivingLicenseImageUrl">
                        <a-card size="small" title="车辆行驶证">
                            <zoom-on-hover :imgNormal="invoiceCheckDetails.drivingLicenseImageUrl" :scale="1.5"></zoom-on-hover>
                        </a-card>
                    </template>
                    <template v-if="invoiceCheckDetails.drivingTempLicenseImageUrl">
                        <a-card size="small" title="车辆临时行驶证">
                            <zoom-on-hover :imgNormal="invoiceCheckDetails.drivingTempLicenseImageUrl" :scale="1.5"></zoom-on-hover>
                        </a-card>
                    </template>
                </a-space>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import { externalDetails, getDetails, updateInvoiceCheckData, invoiceArtificialSingleCheck } from "../../api/invoiceRecognition"
export default {
    props: {
        orderObj: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            invoiceCheckDetails: {},
            certificateObj1: {
                title: "二手车销售统一发票（4项核对）",
                status: 0,
                array: [],
                list: [
                    {
                        title: "开票日期",
                        value: ""
                    },
                    {
                        title: "发票代码",
                        value: ""
                    },
                    {
                        title: "发票号码",
                        value: ""
                    },
                    {
                        title: "开具金额",
                        value: ""
                    }
                ]
            },
            certificateList: [
                {
                    title: "新车主名称与证件号码（4项核对）",
                    status: 0,
                    array: [],
                    list: [
                        {
                            title: "二手车销售统一发票",
                            value: ""
                        },
                        {
                            title: "新车主身份证/营业执照",
                            value: ""
                        },
                        {
                            title: "新车主车辆行驶证",
                            value: ""
                        },
                        {
                            title: "机动车登记证书",
                            value: ""
                        }
                    ]
                },
                {
                    title: "车辆VIN码（3项核对）",
                    status: 1,
                    list: [
                        {
                            title: "二手车销售统一发票",
                            value: ""
                        },
                        {
                            title: "机动车登记证书",
                            value: ""
                        },
                        {
                            title: "ERP系统数据",
                            value: ""
                        }
                    ]
                },
                {
                    title: "旧车车牌号（2项核对）",
                    status: 1,
                    list: [
                        {
                            title: "二手车销售统一发票",
                            value: ""
                        },
                        {
                            title: "ERP系统数据",
                            value: ""
                        }
                    ]
                },
                {
                    title: "发动机号码（2项核对）",
                    status: 1,
                    list: [
                        // {
                        //   title: "二手车销售统一发票",
                        //   value: ""
                        // },
                        {
                            title: "新车主车辆行驶证",
                            value: ""
                        },
                        {
                            title: "机动车登记证书",
                            value: ""
                        },
                    ]
                },
                {
                    title: "机动车登记证号（2项核对）",
                    status: 1,
                    list: [
                        {
                            title: "二手车销售统一发票",
                            value: ""
                        },
                        {
                            title: "机动车登记证书",
                            value: ""
                        }
                    ]
                },
                {
                    title: "新车牌号（2项核对）",
                    status: 1,
                    list: [
                        {
                            title: "新车主车辆行驶证",
                            value: ""
                        },
                        {
                            title: "机动车登记证书",
                            value: ""
                        }
                    ]
                }
            ],
            certificateObj2: {
                title: "开票日期（1项核对）",
                status: 0,
                list: [
                    {
                        title: "发票开票日期不晚于登记日期",
                        value: ""
                    }
                ]
            },
            formInline: {
                title: "",
                value: "",
                index: null,
                indexs: null
            },
            isErp: false,
            isLMP: false
        };
    },
    created() {
        // 是否来源于erp
        if (
            sessionStorage.getItem("cbz_appid") &&
            sessionStorage.getItem("cbz_timestamp")
        ) {
            this.isErp = true;
        } else {
            this.isErp = false;
        }

        // 是否来自原联盟拍
        if (this.$route.query.source == "lmp") {
            this.isLMP = true;
        } else {
            this.isLMP = false;
        }
        this.getDetail();
    },
    methods: {
        getDetail() {
            // 获取核验详情
            const that = this;
            let token = this.$route.query.token;
            let appid = this.$route.query.appid;
            if (token && appid) {
                externalDetails(that.orderObj.orderNumber).then(res => {
                    that.invoiceCheckDetails = res.data;
                    that.initData();
                })
            } else {
                getDetails(that.orderObj.orderNumber).then(res => {
                    that.invoiceCheckDetails = res.data;
                    console.log(that.invoiceCheckDetails)
                    that.initData();
                });
            }
        },
        editForm(e, v, index, indexs) {

            this.formInline = Object.assign(v, { index, indexs });
            console.log(this.formInline)
        },
        handleSubmit() {
            // 修改信息      
            const that = this;
            let obj = {
                json: true,
                id: that.invoiceCheckDetails.inId,
            }
            if (that.formInline.index == 0) {
                // 新车主名称与证件号码
                if (that.formInline.indexs == 0) {
                    // 买方名称
                    obj = Object.assign(obj, {
                        reviseBuyerName: that.formInline.value
                    })
                }

                if (that.formInline.indexs == 1) {
                    // 证件号码
                    obj = Object.assign(obj, {
                        reviseIDNumber: that.formInline.value
                    })
                }
            }

            if (that.formInline.index == 1) {
                // 车辆VIN码
                if (that.formInline.indexs == 0) {
                    // 车辆VIN码
                    obj = Object.assign(obj, {
                        reviseVin: that.formInline.value
                    })
                }
            }

            if (that.formInline.index == 2) {
                // 旧车车牌号
                if (that.formInline.indexs == 0) {
                    // 旧车车牌号
                    obj = Object.assign(obj, {
                        reviseOldPlate: that.formInline.value
                    })
                }
            }

            if (that.formInline.index == 3) {
                // 发动机号码
                if (that.formInline.indexs == 0) {
                    // 发动机号码
                    obj = Object.assign(obj, {
                        reviseEngineNumber: that.formInline.value
                    })
                }
            }

            if (that.formInline.index == 4) {
                // 机动车登记证号
                if (that.formInline.indexs == 0) {
                    // 登记证号
                    obj = Object.assign(obj, {
                        reviseRegistrationCode: that.formInline.value
                    })
                }
            }

            if (that.formInline.index == 5) {
                // 新车牌号
                if (that.formInline.indexs == 0) {
                    // 新车牌号
                    obj = Object.assign(obj, {
                        reviseNewPlate: that.formInline.value
                    })
                }
            }
            updateInvoiceCheckData(obj).then(() => {
                that.formInline = {
                    title: "",
                    value: "",
                    index: null,
                    indexs: null
                }
            });
        },
        initData() {
            const that = this;
            if (that.invoiceCheckDetails) {
                // 统一发票
                that.certificateObj1.array = [];
                that.certificateObj1.status = that.invoiceCheckDetails.fpIsRight;
                that.certificateObj1.list[0].value = that.invoiceCheckDetails.fpkprq;
                that.certificateObj1.list[1].value = that.invoiceCheckDetails.fpdm;
                that.certificateObj1.list[2].value = that.invoiceCheckDetails.fphm;
                // that.certificateObj1.list[3].value = that.invoiceCheckDetails.fpmfdw;
                // that.certificateObj1.list[4].value = that.invoiceCheckDetails.fpdwdmsfzh;
                // that.certificateObj1.list[5].value = that.invoiceCheckDetails.fpdjzh;
                // that.certificateObj1.list[6].value = that.invoiceCheckDetails.fpcp;
                // that.certificateObj1.list[7].value = that.invoiceCheckDetails.fpvin;
                that.certificateObj1.list[3].value = that.invoiceCheckDetails.fpje ? (that.invoiceCheckDetails.fpje + "元") : "-";
                that.certificateObj1.array.push({
                    title: that.invoiceCheckDetails.contrastExplain
                });

                // 新车主名称与证件号
                that.certificateList[0].status = that.invoiceCheckDetails.xczMcZjHmIsRight;
                that.certificateList[0].array = [];
                that.certificateList[0].array.push({
                    title: "买方名称",
                    value: that.invoiceCheckDetails.reviseBuyerName
                });
                that.certificateList[0].array.push({
                    title: "证件号码",
                    value: that.invoiceCheckDetails.reviseIDNumber
                });
                that.certificateList[0].list[0].value = that.invoiceCheckDetails.xczMcZjHmFpIsRight;
                that.certificateList[0].list[1].value = that.invoiceCheckDetails.xczMcZjHmSfzIsRight;
                that.certificateList[0].list[2].value = that.invoiceCheckDetails.xczMcZjHmXszIsRight;
                that.certificateList[0].list[3].value = that.invoiceCheckDetails.xczMcZjHmDjzIsRight;

                // 车辆VIN码
                that.certificateList[1].status = that.invoiceCheckDetails.vinIsRight;
                that.certificateList[1].array = [];
                that.certificateList[1].array.push({
                    title: "车辆VIN码",
                    value: that.invoiceCheckDetails.reviseVin
                });
                that.certificateList[1].list[0].value = that.invoiceCheckDetails.vinFpIsRight;
                that.certificateList[1].list[1].value = that.invoiceCheckDetails.vinDjzIsRight;
                that.certificateList[1].list[2].value = that.invoiceCheckDetails.vinErpIsRight;

                // 旧车车牌号
                that.certificateList[2].status = that.invoiceCheckDetails.oldCpIsRight;
                that.certificateList[2].array = [];
                that.certificateList[2].array.push({
                    title: "旧车车牌号",
                    value: that.invoiceCheckDetails.reviseOldPlate
                });
                that.certificateList[2].list[0].value = that.invoiceCheckDetails.oldCpFpIsRight;
                that.certificateList[2].list[1].value = that.invoiceCheckDetails.oldCpErpIsRight;

                // 发动机号码
                that.certificateList[3].status = that.invoiceCheckDetails.fdjHmIsRight;
                that.certificateList[3].array = [];
                that.certificateList[3].array.push({
                    title: "发动机号码",
                    value: that.invoiceCheckDetails.reviseEngineNumber
                });
                // that.certificateList[3].list[0].value = that.invoiceCheckDetails.fdjHmFpIsRight;
                that.certificateList[3].list[0].value = that.invoiceCheckDetails.fdjHmXczXszIsRight;
                that.certificateList[3].list[1].value = that.invoiceCheckDetails.fdjHmJdcDjzIsRight;

                // 机动车登记证号
                that.certificateList[4].status = that.invoiceCheckDetails.jdcDjZhIsRight;
                that.certificateList[4].array = [];
                that.certificateList[4].array.push({
                    title: "登记证号",
                    value: that.invoiceCheckDetails.reviseRegistrationCode
                });
                that.certificateList[4].list[0].value = that.invoiceCheckDetails.jdcDjZhFpIsRight;
                that.certificateList[4].list[1].value = that.invoiceCheckDetails.jdcDjZZsIsRight;

                // 新车牌号
                that.certificateList[5].status = that.invoiceCheckDetails.xcphIsRight;
                that.certificateList[5].array = [];
                that.certificateList[5].array.push({
                    title: "新车牌号",
                    value: that.invoiceCheckDetails.reviseNewPlate
                });
                that.certificateList[5].list[0].value = that.invoiceCheckDetails.xcphXszIsRight;
                that.certificateList[5].list[1].value = that.invoiceCheckDetails.xcphClDjZsIsRight;

                // 开票日期
                that.certificateObj2.status = that.invoiceCheckDetails.kprqIsRight;
                that.certificateObj2.list[0].value = that.invoiceCheckDetails.kprqKpRqBwyDjRqIsRight;
            }
        },
        invoiceArtificialSingleCheck(type) {
            // 校验
            const that = this;
            invoiceArtificialSingleCheck({
                json: true,
                checkType: type,
                id: that.invoiceCheckDetails.inId
            }).then(() => {
                that.getDetail();
            })
        },
    }
};
</script>
<style lang="scss">
.ml_certificate {
    .ant-card-head-title {
        font-weight: bold;
        font-size: 18px;
    }
}
</style>
